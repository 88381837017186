import { Create } from "@refinedev/mui";
import DatabaseForm from "components/common/DatabaseForm";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from '@refinedev/core';
import { FieldValues } from "react-hook-form";
import { Box, CircularProgress } from "@mui/material";

export const DatabasesCreate: React.FC<IResourceComponentsProps> = () => {

  const {
    refineCore: { formLoading, onFinish, mutationResult },
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      onMutationSuccess: (data, variables, context) => { },
      onMutationError: (error, variables, context) => { },
    },
  });

  const onFinishHandler = async (data: FieldValues) => {
    // console.log(JSON.parse(data?.sheets_json));
    const sheetData = JSON.parse(data?.sheets_json);
    
    for (const sheet in sheetData) {
      if (Object.prototype.hasOwnProperty.call(sheetData, sheet)) {
        console.log(sheet);
      }
    }
    for (const sheet in sheetData) {
      if (Object.prototype.hasOwnProperty.call(sheetData, sheet)) {
        console.log(JSON.parse(sheetData[sheet]));
      }
    }  
    await onFinish({ ...data });
  }

  return (
    <Create saveButtonProps={{ style: { display: "none" } }}>
      {
        formLoading ?
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress />
          </Box> :
          <DatabaseForm
            type="create"
            setValue={setValue}
            register={register}
            onFinishHandler={onFinishHandler}
            formLoading={formLoading}
            handleSubmit={handleSubmit}
          />}
    </Create>
  );
};
