import {
    Box,
    FormHelperText,
    TextField,
} from "@mui/material"
import { read, utils } from "xlsx";
import { DatabaseFormProps } from "interfaces/common";
import CustomButton from "./CustomButton";
import React from "react";

const DatabaseForm = ({
    type,
    register,
    setValue,
    onFinishHandler,
    formLoading,
    handleSubmit,
}: DatabaseFormProps) => {

    const readUploadFile = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.currentTarget.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e?.target?.result;
                const workbook = read(data, { type: "array" });
                let json_output: Object = {};
                workbook.SheetNames.forEach(sheetName => {
                    let worksheet = workbook.Sheets[sheetName];
                    json_output = { ...json_output, [sheetName]: JSON.stringify(utils.sheet_to_json(worksheet)) };
                });
                setValue('sheets_json', JSON.stringify(json_output));
            };
            reader.readAsArrayBuffer(e.currentTarget.files[0]);
        }
    }

    return (
        <Box>
            <form style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
                onSubmit={handleSubmit(onFinishHandler)}
            >
                <FormHelperText
                    sx={{
                        fontWeight: 500,
                        margin: "10px 0",
                        fontSize: 16,
                        color: "#11142d",
                    }}
                >
                    Name
                </FormHelperText>
                <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    color="info"
                    variant="outlined"
                    {...register("name", { required: true })}
                />

                <FormHelperText
                    sx={{
                        fontWeight: 500,
                        margin: "10px 0",
                        fontSize: 16,
                        color: "#11142d",
                    }}
                >
                    Source
                </FormHelperText>
                <TextField
                    fullWidth
                    required
                    id="outlined-basic"
                    color="info"
                    variant="outlined"
                    {...register("source", { required: true })}
                />

                <FormHelperText
                    sx={{
                        fontWeight: 500,
                        margin: "10px 0",
                        fontSize: 16,
                        color: "#11142d",
                    }}
                >
                    Upload Excel File
                </FormHelperText>
                <input
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={readUploadFile}
                // {...register("file", { required: true })}
                />

                <FormHelperText
                    sx={{
                        fontWeight: 500,
                        margin: "10px 0",
                        fontSize: 16,
                        color: "#11142d",
                    }}
                >
                    Data Preview
                </FormHelperText>
                <TextField
                    fullWidth
                    disabled
                    required
                    id="sheets_json"
                    color="info"
                    variant="outlined"
                    {...register("sheets_json", { required: true })}
                />

                <CustomButton
                    type="submit"
                    disabled={formLoading}
                    title={"Submit"}
                    backgroundColor="#475be8"
                    color="#fcfcfc"
                />
            </form>
        </Box>
    )
}

export default DatabaseForm