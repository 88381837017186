import { Create } from "@refinedev/mui";
import Form from "components/common/Form";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps } from '@refinedev/core';
import { FieldValues } from "react-hook-form";
import { Box, CircularProgress } from "@mui/material";

export const UsersCreate: React.FC<IResourceComponentsProps> = () => {

  const {
    refineCore: { formLoading, onFinish, mutationResult },
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      onMutationSuccess: (data, variables, context) => { },
      onMutationError: (error, variables, context) => { },
    },
  });

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({ ...data });
  }

  return (
    <Create saveButtonProps={{ style: { display: "none" } }}>
      {
        formLoading ?
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress />
          </Box> :
          <Form
            type="create"
            register={register}
            onFinishHandler={onFinishHandler}
            formLoading={formLoading}
            handleSubmit={handleSubmit}
            // fields={['name','email','password']}
            fields={[
              {
                name: 'name',
                type: 'name',
                options: {}
              },
              {
                name: 'email',
                type: 'email',
                options: {}
              },
              {
                name: 'password',
                type: 'password',
                options: {}
              },
            ]}
          />}
    </Create>
  );
};
