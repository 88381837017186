import { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
// import { parseJwt } from "utils/parse-jwt";
import Form from "components/common/Form";
import { useForm } from "@refinedev/react-hook-form";
import { useLogin } from '@refinedev/core';
import { FieldValues } from "react-hook-form";

import { CredentialValues } from "../interfaces/google";
import { axiosInstance } from "utils/axios";

// Todo: Update your Google Client ID here
// const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;


export const Login: React.FC = () => {
  const [isUserAllowed, setIsUserAllowed] = useState(false);
  const [showIsUserAllowed, setShowIsUserAllowed] = useState(false);
  const { mutate: login } = useLogin<CredentialValues>();

  // const GoogleButton = (): JSX.Element => {
  //   const divRef = useRef<HTMLDivElement>(null);

  //   useEffect(() => {
  //     if (typeof window === "undefined" || !window.google || !divRef.current) {
  //       return;
  //     }

  //     try {
  //       window.google.accounts.id.initialize({
  //         ux_mode: "popup",
  //         client_id: GOOGLE_CLIENT_ID,
  //         callback: async (res: CredentialResponse) => {

  //           if (res.credential) {
  //             const profileObj = res.credential ? parseJwt(res.credential) : null;
  //             console.log(res.credential);

  //             if (profileObj && profileObj.email) {
  //               // const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/byEmail/${profileObj.email}`,
  //               //   {
  //               //     method: 'GET',
  //               //     headers: { 'Content-Type': 'application/json' },
  //               //   }
  //               // )
  //               // const data = await response.json();
  //               // if (data.userExists) {
  //               //   setIsUserAllowed(true);
  //               //   login({ ...res, data });
  //               // } else setShowIsUserAllowed(true)
  //             }
  //           }
  //         },
  //       });
  //       window.google.accounts.id.renderButton(divRef.current, {
  //         theme: "filled_blue",
  //         size: "medium",
  //         type: "standard",
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, []);

  //   return <div ref={divRef} />;
  // };

  const {
    refineCore: { formLoading, mutationResult },
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    refineCoreProps: {
      onMutationSuccess: (data, variables, context) => { },
      onMutationError: (error, variables, context) => { },
    },
  });

  const onFinishHandler = async (userData: FieldValues) => {
    try {
      const res = await axiosInstance.get(`${process.env.REACT_APP_BACKEND_URL_SANCTUM}`);

      if (res.status === 204) {
        login({
          password: userData.password,
          email: userData.email
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography align="center" color={"text.primary"} fontSize="40px">
        S1
      </Typography>
      <Form
        type="create"
        register={register}
        onFinishHandler={onFinishHandler}
        formLoading={formLoading}
        handleSubmit={handleSubmit}
        // fields={['email', 'password']}
        fields={[
          {
            name: 'email',
            type: 'email',
            default: '',
            options: {}
          },
          {
            name: 'password',
            type: 'password',
            default: '',
            options: {}
          },
        ]}
      />
      {/* <GoogleButton /> */}
      {
        !isUserAllowed && showIsUserAllowed && <Typography align="center" color={"red"} fontSize="20px">
          User Not Allowed
        </Typography>
      }
    </Container>
  );
};
