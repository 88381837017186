import React, { useEffect } from "react";
import {
  EditButton,
  ShowButton,
  List
} from "@refinedev/mui";
// import { DataGrid, GridColumns, GridColDef } from "@mui/x-data-grid";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IResourceComponentsProps } from "@refinedev/core";
import { axiosInstance } from "utils/axios";

const BACKEND_URL = process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : '' : process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';

const getUsers = async (pageSize: Number, currentPage: Number, setUserData: Function, setIsLoading: Function, setRowCount: Function) => {
  const response = await axiosInstance.get(`${BACKEND_URL}/users?page_size=${pageSize}&page=${currentPage}`);

  if (response.status === 200) {
    setRowCount(response?.data?.meta?.total)
    setUserData(response?.data?.data)
    setIsLoading(false)
    return { success: true };
  }
  else return { success: false };
}

export const UsersList: React.FC<IResourceComponentsProps> = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pageSize, setPageSize] = React.useState<number>(5);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [rowCount, setRowCount] = React.useState<number>(0);
  const [userData, setUserData] = React.useState<Array<Object>>([]);

  useEffect(() => {
    getUsers(pageSize, currentPage, setUserData, setIsLoading, setRowCount);
  }, [currentPage]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
    },
    {
      field: 'created_by.email',
      headerName: 'Created By',
      renderCell: function render({ row }) {
        return (row?.created_by?.email);
      },
      width: 250,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: function render({ row }) {
        return (
          <>
            {row.email === 'nikhilmehta1501@gmail.com' ? '' : <EditButton hideText recordItemId={row.user_id} />}
            <ShowButton hideText recordItemId={row.user_id} />
          </>
        );
      },
      align: "center",
      headerAlign: "center",
      minWidth: 80,
    },
  ];

  return (
    <List>
      <DataGrid
        getRowId={(row) => row.user_id}
        columns={columns}
        rows={userData}
        rowCount={rowCount}
        loading={isLoading}
        disableSelectionOnClick
        rowsPerPageOptions={[20, 50, 100]}
        autoHeight
      />
    </List>
  );
};
