import { useShow, IResourceComponentsProps, useDelete } from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  DateField
} from "@refinedev/mui";
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Stack, CircularProgress, Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import CustomButton from 'components/common/CustomButton';
import { useNotification } from "@refinedev/core";
import { axiosInstance } from "utils/axios";

const BACKEND_URL = process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : '' : process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';

export const UsersShow: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { queryResult } = useShow();
  const { data, isLoading, isError } = queryResult;
  const { id } = useParams();
  const { open } = useNotification();

  const record = data?.data?.user;

  //TODO: Use useDelete
  const handleDeleteUser = async () => {
    const response = window.confirm(
      "Are you sure you want to delete this User?",
    );
    if (response) {
      const response = await axiosInstance.delete(`${BACKEND_URL}`, { data: { idToDelete: id, } });

      const data = response.data;

      if (response.status === 200 || response.status === 201) {
        open?.({
          type: "success",
          message: data?.message,
          description: "Success",
        });
        navigate("/users");
      } else {
        open?.({
          type: "error",
          message: data?.message,
          description: "Error",
        });
      }
    }
  };

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      canEdit={record?.email !== "nikhilmehta1501@gmail.com"}
      resource="users"
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          {
            record?.email === "nikhilmehta1501@gmail.com" ? '' :
              <CustomButton
                type="button"
                icon={<DeleteIcon />}
                fullWidth={false}
                disabled={isLoading}
                title={"Delete"}
                backgroundColor="transparent"
                color="red"
                sx={{
                  backgroundColor: "transparent",
                  color: "#ec0006",
                  padding: "5px",
                  minWidth: 100,
                  fontSize: 16,
                  fontWeight: 600,
                  gap: "10px",
                  textTransform: "capitalize",
                  "&:hover": {
                    opacity: 0.9,
                    backgroundColor: "transparent",
                  },
                }}
                handleClick={handleDeleteUser}
              />
          }
        </>
      )}
    >
      {
        isLoading ?
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress />
          </Box> :
          <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">
              Name
            </Typography>
            <TextField value={record?.name} />
            <Typography variant="body1" fontWeight="bold">
              Email
            </Typography>
            <TextField value={record?.email} />
            <Typography variant="body1" fontWeight="bold">
              Created By
            </Typography>
            <TextField value={record?.created_by?.email} />
            <Typography variant="body1" fontWeight="bold">
              Created At
            </Typography>
            <DateField value={record?.createdAt} />
          </Stack>
      }
    </Show>
  );
};
