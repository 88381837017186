
import { Edit } from "@refinedev/mui";
import Form from "components/common/Form";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useNotification } from '@refinedev/core';
import { FieldValues } from "react-hook-form";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, CircularProgress } from "@mui/material";
import CustomButton from "components/common/CustomButton";
import { axiosInstance } from "utils/axios";
import { useNavigate } from "react-router-dom";

const BACKEND_URL = process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : '' : process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';

export const UsersEdit: React.FC<IResourceComponentsProps> = () => {

  const navigate = useNavigate();
  const { open } = useNotification();

  const {
    refineCore: { formLoading, onFinish, mutationResult, queryResult },
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    refineCoreProps: {
      onMutationSuccess: (data, variables, context) => { },
      onMutationError: (error, variables, context) => { },
    },
  });

  const fields = [
    {
      name: 'name',
      type: 'name',
      default: queryResult?.data?.data?.user?.name,
      options: {}
    },
    {
      name: 'email',
      type: 'email',
      default: queryResult?.data?.data?.user?.email,
      options: {}
    },
    {
      name: 'password',
      type: 'password',
      default: '',
      options: {}
    },
  ]

  //TODO: Use useDelete
  const handleDeleteUser = async () => {
    const response = window.confirm(
      "Are you sure you want to delete this User?",
    );
    if (response) {
      let id = queryResult?.data?.data?.user?.id;
      const response = await axiosInstance.delete(`${BACKEND_URL}/users/${id}`, { data: { idToDelete: id, } });

      const data = response.data;

      if (response.status === 200 || response.status === 201) {
        open?.({
          type: "success",
          message: data?.message,
          description: "Success",
        });
        navigate("/users");
      } else {
        open?.({
          type: "error",
          message: data?.message,
          description: "Error",
        });
      }
    }
  };

  const onFinishHandler = async (data: FieldValues) => {
    const oldData = queryResult?.data?.data?.user;
    data.id = oldData.id;
    if (data.email === 'nikhilmehta1501@gmail.com') {
      open?.({
        type: "error",
        message: "Access Denied",
        description: "Error",
      });
      navigate('/users');
      return;
    }

    if (
      data.name !== oldData.name ||
      data.email !== oldData.email ||
      data.password !== ''
    ) {
      if (data.password === '') delete data.password;
      await onFinish({ ...data });
    }
    else {
      open?.({
        type: "error",
        message: "Please enter new data",
        description: "Error",
      });
    }
  }

  return (
    <Edit
      saveButtonProps={{ style: { display: "none" } }}
      deleteButtonProps={{ style: { display: "none" } }}
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          <CustomButton
            type="button"
            icon={<DeleteIcon />}
            fullWidth={false}
            disabled={formLoading}
            title={"Delete"}
            backgroundColor="transparent"
            color="red"
            sx={{
              backgroundColor: "transparent",
              color: "#ec0006",
              padding: "5px",
              minWidth: 100,
              fontSize: 16,
              fontWeight: 600,
              gap: "10px",
              textTransform: "capitalize",
              "&:hover": {
                opacity: 0.9,
                backgroundColor: "transparent",
              },
            }}
            handleClick={handleDeleteUser}
          />
        </>
      )}
    >
      {
        formLoading ?
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress />
          </Box> :
          <Form
            type="edit"
            setValue={setValue}
            register={register}
            onFinishHandler={onFinishHandler}
            formLoading={formLoading}
            handleSubmit={handleSubmit}
            // fields={['name','email','password']}
            fields={fields}
          />}
    </Edit>
  );
};

