import {
    Box,
    FormControl,
    FormHelperText,
    TextField,
} from "@mui/material"
import { FormProps } from "interfaces/common";
import CustomButton from "./CustomButton";
import { useEffect } from "react";

const Form = ({
    type,
    register,
    setValue,
    onFinishHandler,
    formLoading,
    handleSubmit,
    fields,
}: FormProps) => {

    useEffect(() => {
        if (type === 'edit')
            fields.forEach(fieldObject => setValue(fieldObject.name, fieldObject.default))
    }, [])

    return (
        <Box>
            <form style={{
                marginTop: "20px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
                onSubmit={handleSubmit(onFinishHandler)}
            >
                {
                    fields.map((fieldObject, index) => {
                        return (
                            fieldObject.type === 'password' ?
                                <FormControl key={index}>
                                    <FormHelperText
                                        sx={{
                                            fontWeight: 500,
                                            margin: "10px 0",
                                            fontSize: 16,
                                            color: "#11142d",
                                        }}
                                    >
                                        {fieldObject.name.charAt(0).toUpperCase() + fieldObject.name.slice(1)}
                                    </FormHelperText>
                                    <TextField
                                        fullWidth
                                        required={type !== 'edit'}
                                        placeholder={type === 'edit' ? 'Enter New Password' : ''}
                                        type="password"
                                        id={`${fieldObject.name}-${fieldObject.type}-${index}`}
                                        color="info"
                                        variant="outlined"
                                        {...register('password', { required: type !== 'edit' })}
                                    />
                                </FormControl> :
                                <FormControl key={index}>
                                    <FormHelperText
                                        sx={{
                                            fontWeight: 500,
                                            margin: "10px 0",
                                            fontSize: 16,
                                            color: "#11142d",
                                        }}
                                    >
                                        {fieldObject.name.charAt(0).toUpperCase() + fieldObject.name.slice(1)}
                                    </FormHelperText>
                                    {
                                        type === 'edit' ?
                                            <TextField
                                                placeholder={fieldObject?.default}
                                                onChange={e => { setValue(`${fieldObject.name}`, e.target.value) }}
                                                fullWidth
                                                required
                                                type={`${fieldObject.type === 'email' ? 'email' : ''}`}
                                                disabled={type === 'edit' && fieldObject.type === 'email'}
                                                id={`${fieldObject.name}-${fieldObject.type}-${index}`}
                                                color="info"
                                                variant="outlined"
                                                {...register(`${fieldObject.name}`, { required: true })}
                                            /> :
                                            <TextField
                                                fullWidth
                                                required
                                                type={`${fieldObject.type}`}
                                                id={`${fieldObject.name}-${fieldObject.type}-${index}`}
                                                color="info"
                                                variant="outlined"
                                                {...register(`${fieldObject.name}`, { required: true })}
                                            />
                                    }
                                </FormControl>
                        );
                    })
                }
                <CustomButton
                    type="submit"
                    disabled={formLoading}
                    title={"Submit"}
                    backgroundColor="#475be8"
                    color="#fcfcfc"
                />
                {/* <FormControl>
                    <FormHelperText
                        sx={{
                            fontWeight: 500,
                            margin: "10px 0",
                            fontSize: 16,
                            color: "#11142d",
                        }}
                    >
                        Name
                    </FormHelperText>
                    <TextField
                        fullWidth
                        required
                        id="outlined-basic"
                        color="info"
                        variant="outlined"
                        {...register("name", { required: true })}
                    />
                </FormControl>
                <FormControl>
                    <FormHelperText
                        sx={{
                            fontWeight: 500,
                            margin: "10px 0",
                            fontSize: 16,
                            color: "#11142d",
                        }}
                    >
                        Email
                    </FormHelperText>
                    <TextField
                        fullWidth
                        required
                        id="outlined-basic"
                        color="info"
                        variant="outlined"
                        {...register("email", { required: true })}
                    />
                </FormControl> */}
            </form>
        </Box>
    )
}

export default Form