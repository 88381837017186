import {
  AuthBindings,
  Authenticated,
  Refine,
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  notificationProvider,
  RefineSnackbarProvider,
  ThemedLayoutV2,
  ThemedTitleV2
} from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
// import dataProvider from "@refinedev/simple-rest";  //Default Data Provider
import { myDataProvider } from "utils/myDataProvider";
import { axiosInstance } from "utils/axios";
import { CredentialValues } from "interfaces/google";
import {
  UsersCreate,
  UsersEdit,
  UsersList,
  UsersShow,
} from "pages/users";
import {
  DatabasesCreate,
  DatabasesEdit,
  DatabasesList,
  DatabasesShow,
} from "pages/databases";
import {
  BlogPostCreate,
  BlogPostEdit,
  BlogPostList,
  BlogPostShow,
} from "pages/blog-posts";
import {
  Table1Create,
  Table1Edit,
  Table1List,
  Table1Show,
} from "pages/table1";
import { Login } from "pages/login";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./components/header";
import { Sider } from "./components/sider";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { MuiInferencer, MuiListInferencer } from "@refinedev/inferencer/mui";
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import TableChartIcon from '@mui/icons-material/TableChart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const BACKEND_URL = process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : '' : process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';

function App() {
  const authProvider: AuthBindings = {
    login: async ({ email, password }: CredentialValues) => {

      const response = await axiosInstance.post(
        `${BACKEND_URL}/login`,
        JSON.stringify({
          password: password,
          email: email
        }),
        { headers: { 'Content-Type': 'application/json' } },
      )

      if ((response.status === 200 || response.status === 201) || response?.data?.userExists) {
        localStorage.setItem("user", JSON.stringify(response?.data?.user));
        localStorage.setItem("token", `${response?.data?.be_token}`);
        return {
          success: true,
          redirectTo: "/",
        };
      } else {
        return {
          success: false,
        }
      }
    },
    logout: async () => {
      const token = localStorage.getItem("token");

      const response = axiosInstance.post(`${BACKEND_URL}/logout`);

      if (token && typeof window !== "undefined" && (await response).status === 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        axiosInstance.defaults.headers.common = {};
        // window.google?.accounts.id.revoke(token, () => {
        //   return {};
        // });
        return {
          success: true,
          redirectTo: "/login",
        }
      } else {
        return { success: false }
      }

    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
    check: async () => {
      const token = localStorage.getItem("token");

      if (token) { return { authenticated: true } }

      return {
        authenticated: false,
        error: {
          message: "Check failed",
          name: "Token not found",
        },
        logout: true,
        redirectTo: "/login",
      };
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      const user = localStorage.getItem("user");
      if (user) { return JSON.parse(user) }

      return null;
    },
  };

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
              dataProvider={myDataProvider(BACKEND_URL)}
              // dataProvider={myDataProvider('https://api.fake-rest.refine.dev')}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              resources={[
                {
                  name: "users",
                  list: "/users",
                  create: "/users/create",
                  edit: "/users/edit/:id",
                  show: "/users/show/:id",
                  meta: {
                    label: "Users",
                    icon: <AccountCircleIcon />,
                    canDelete: true,
                  },
                },
                {
                  name: "databases",
                  list: "/databases",
                  create: "/databases/create",
                  edit: "/databases/edit/:id",
                  show: "/databases/show/:id",
                  meta: {
                    label: "Databases",
                    icon: <TableChartIcon />,
                    canDelete: true,
                  },
                },
                // {
                //   name: "blog_posts",
                //   list: "/blog-posts",
                //   create: "/blog-posts/create",
                //   edit: "/blog-posts/edit/:id",
                //   show: "/blog-posts/show/:id",
                //   meta: {
                //     canDelete: true,
                //   },
                // },
                // {
                //   name: "categories",
                //   list: "/categories",
                //   create: "/categories/create",
                //   edit: "/categories/edit/:id",
                //   show: "/categories/show/:id",
                //   meta: {
                //     canDelete: true,
                //   },
                // },
                // {
                //   name: "table1",
                //   list: "/table1",
                //   create: "/table1/create",
                //   edit: "/table1/edit/:id",
                //   show: "/table1/show/:id",
                //   meta: {
                //     label: "Table 1",
                //     icon: <TableRowsOutlinedIcon />,
                //     canDelete: true,
                //   },
                // }
              ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
                disableTelemetry: true
              }}
            >
              <Routes>
                <Route
                  element={
                    <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                      <ThemedLayoutV2
                        Header={() => <Header isSticky={true} />}
                        Sider={() =>
                          <Sider
                            Title={({ collapsed }) => (
                              <ThemedTitleV2
                                // collapsed is a boolean value that indicates whether the <Sidebar> is collapsed or not
                                collapsed={collapsed}
                                text="S1"
                                icon={null}
                              />
                            )
                            }
                          />
                        }
                      >
                        <Outlet />
                      </ThemedLayoutV2>
                    </Authenticated>
                  }
                >
                  <Route
                    index
                    element={<NavigateToResource resource="users" />}
                  />
                  <Route path="/users">
                    <Route index element={<UsersList />} />
                    <Route path="create" element={<UsersCreate />} />
                    <Route path="edit/:id" element={<UsersEdit />} />
                    <Route path="show/:id" element={<UsersShow />} />
                  </Route>
                  <Route path="/databases">
                    <Route index element={<DatabasesList />} />
                    <Route path="create" element={<DatabasesCreate />} />
                    <Route path="edit/:id" element={<DatabasesEdit />} />
                    <Route path="show/:id" element={<DatabasesShow />} />
                  </Route>
                  {/* <Route path="/categories">
                    <Route index element={<MuiListInferencer />} />
                    <Route path="create" element={<MuiInferencer />} />
                    <Route path="edit/:id" element={<MuiInferencer />} />
                    <Route path="show/:id" element={<MuiInferencer />} />
                  </Route>
                  <Route path="/blog-posts">
                    <Route index element={<BlogPostList />} />
                    <Route path="create" element={<BlogPostCreate />} />
                    <Route path="edit/:id" element={<BlogPostEdit />} />
                    <Route path="show/:id" element={<BlogPostShow />} />
                  </Route>
                  <Route path="/table1">
                    <Route index element={<Table1List />} />
                    <Route path="create" element={<Table1Create />} />
                    <Route path="edit/:id" element={<Table1Edit />} />
                    <Route path="show/:id" element={<Table1Show />} />
                  </Route> */}

                  <Route path="*" element={<ErrorComponent />} />
                </Route>
                <Route
                  element={
                    <Authenticated fallback={<Outlet />}>
                      <NavigateToResource />
                    </Authenticated>
                  }
                >
                  <Route path="/login" element={<Login />} />
                </Route>
              </Routes>

              <RefineKbar />
              <UnsavedChangesNotifier />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter >
  );
}

export default App;
