import { useShow, IResourceComponentsProps, useDelete, useGetIdentity } from "@refinedev/core";
import {
  Show,
  TextFieldComponent as TextField,
  DateField
} from "@refinedev/mui";
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography, Stack, CircularProgress, Box } from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import CustomButton from 'components/common/CustomButton';
import { useNotification } from "@refinedev/core";
import { axiosInstance } from "utils/axios";
import { IUser } from "interfaces/common";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";

const BACKEND_URL = process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : '' : process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';

const getDatabases = async (
  database_id: String,
  setIsRecordsLoading: Function,
  setRecordsData: Function,
  setRowCount: Function
) => {
  const response = await axiosInstance.get(`${BACKEND_URL}/databases/${database_id}/records`);

  if (response.status === 200) {
    setRecordsData(response?.data?.data)
    setIsRecordsLoading(false)
    setRowCount(response?.data?.data.length)
    return { success: true };
  }
  else return { success: false };
}

export const DatabasesShow: React.FC<IResourceComponentsProps> = () => {
  const navigate = useNavigate();
  const { queryResult } = useShow();
  const { data, isLoading, isError } = queryResult;
  const { id } = useParams();
  const { open } = useNotification();

  const { data: user } = useGetIdentity<IUser>();

  const record = data?.data?.database;

  const [isRecordsLoading, setIsRecordsLoading] = React.useState<boolean>(true);
  const [rowCount, setRowCount] = React.useState<number>(0);
  const [recordsData, setRecordsData] = React.useState<Array<Object>>([]);

  //TODO: Use useDelete
  const handleDeleteDatabase = async () => {
    const response = window.confirm(
      "Are you sure you want to delete this Database?",
    );
    if (response) {
      const response = await axiosInstance.delete(`${BACKEND_URL}/databases/${id}`, { data: { idToDelete: id, } });

      const data = response.data;

      if (response.status === 200 || response.status === 201) {
        open?.({
          type: "success",
          message: data?.message,
          description: "Success",
        });
        navigate("/databases");
      } else {
        open?.({
          type: "error",
          message: data?.message,
          description: "Error",
        });
      }
    }
  };

  useEffect(() => {
    if (!isLoading)
      getDatabases(record?.database_id, setIsRecordsLoading, setRecordsData, setRowCount);
  }, [isLoading]);

  let columns: GridColDef[] = [];

  let firstRecord = recordsData[0];

  for (const key in firstRecord) {
    if (Object.prototype.hasOwnProperty.call(firstRecord, key)) {
      if (key !== "record_id") {
        let fieldObject = {
          field: key,
          headerName: key,
          flex: 1
        };
        columns.push(fieldObject);
      }
    }
  }

  return (
    <Show
      isLoading={isLoading}
      canDelete={false}
      canEdit={record?.created_by?.email === user?.email}
      resource="databases"
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          {
            record?.created_by?.email !== user?.email ? '' :
              <CustomButton
                type="button"
                icon={<DeleteIcon />}
                fullWidth={false}
                disabled={isLoading}
                title={"Delete"}
                backgroundColor="transparent"
                color="red"
                sx={{
                  backgroundColor: "transparent",
                  color: "#ec0006",
                  padding: "5px",
                  minWidth: 100,
                  fontSize: 16,
                  fontWeight: 600,
                  gap: "10px",
                  textTransform: "capitalize",
                  "&:hover": {
                    opacity: 0.9,
                    backgroundColor: "transparent",
                  },
                }}
                handleClick={handleDeleteDatabase}
              />
          }
        </>
      )}
    >
      {
        isLoading ?
          <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress />
          </Box> :
          <>
            <Stack gap={1}>
              <Typography variant="body1" fontWeight="bold">
                Name
              </Typography>
              <TextField value={record?.name} />
              <Typography variant="body1" fontWeight="bold">
                Source
              </Typography>
              <TextField value={record?.source} />
              <Typography variant="body1" fontWeight="bold">
                Created By
              </Typography>
              <TextField value={record?.created_by?.email} />
              <Typography variant="body1" fontWeight="bold">
                Created At
              </Typography>
              <DateField value={record?.createdAt} />
            </Stack>

            <DataGrid
              getRowId={(row) => row.record_id}
              columns={columns}
              rows={recordsData}
              rowCount={rowCount}
              loading={isRecordsLoading}
              disableSelectionOnClick
              autoHeight
              rowsPerPageOptions={[50, 100]}
            />
          </>
      }
    </Show>
  );
};
