import React, { useEffect } from "react";
import {
  EditButton,
  ShowButton,
  List
} from "@refinedev/mui";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IResourceComponentsProps, useGetIdentity } from "@refinedev/core";
import { axiosInstance } from "utils/axios";
import { IUser } from "interfaces/common";

const BACKEND_URL = process.env.REACT_APP_MODE === 'dev' ? process.env.REACT_APP_BACKEND_URL_DEV ? process.env.REACT_APP_BACKEND_URL_DEV : '' : process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '';

const getDatabases = async (pageSize: Number, currentPage: Number, setDatabasesData: Function, setIsLoading: Function, setRowCount: Function) => {
  // const getDatabases = async (paginationModel, setPaginationModel: Function, setDatabasesData: Function, setIsLoading: Function, setRowCount: Function) => {
  const response = await axiosInstance.get(`${BACKEND_URL}/databases?page_size=${pageSize}&page=${currentPage}`);

  if (response.status === 200) {
    console.log(response?.data?.data);
    setDatabasesData(response?.data?.data)
    setIsLoading(false)
    setRowCount(response?.data?.meta?.total)
    return { success: true };
  }
  else return { success: false };
}

export const DatabasesList: React.FC<IResourceComponentsProps> = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [rowCount, setRowCount] = React.useState<number>(0);
  const [databasesData, setDatabasesData] = React.useState<Array<Object>>([]);

  const { data: user } = useGetIdentity<IUser>();

  useEffect(() => {
    getDatabases(pageSize, currentPage, setDatabasesData, setIsLoading, setRowCount);
  }, [currentPage, pageSize]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 150,
    },
    {
      field: 'created_by.email',
      headerName: 'Created By',
      renderCell: function render({ row }) {
        return (row?.created_by?.email);
      },
      width: 250,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: function render({ row }) {
        return (
          <>
            {/* {row.email === 'nikhilmehta1501@gmail.com' ? '' : <EditButton hideText recordItemId={row.id} />} */}
            {row?.created_by?.email === user?.email ? <EditButton hideText recordItemId={row.database_id} /> : ''}
            <ShowButton hideText recordItemId={row.database_id} />
          </>
        );
      },
      align: "center",
      headerAlign: "center",
      minWidth: 80,
    },
  ];

  return (
    <List>
      <DataGrid
        getRowId={(row) => row.database_id}
        columns={columns}
        rows={databasesData}
        rowCount={rowCount}
        loading={isLoading}
        disableSelectionOnClick
        rowsPerPageOptions={[50, 100]}
        autoHeight
      />
    </List>
  );
};
