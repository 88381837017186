import { HttpError } from "@refinedev/core";
import axios, { AxiosRequestConfig } from "axios";

export const axiosInstance = axios.create();

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(
    (request: AxiosRequestConfig) => {
        const token = localStorage.getItem("token");
        if (request.headers) {
            request.headers["Authorization"] = `Bearer ${token}`;
            request.headers["Accept"] = `application/json`;
            request.headers["Access-Control-Allow-Credentials"] = true;
        } else {
            request.headers = {
                Authorization: `Bearer ${token}`,
                Accept: `application/json`,
                "Access-Control-Allow-Credentials": true,
            };
        }

        // console.log(request);

        return request;
    },
);

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        // console.log(customError);

        if (customError.statusCode === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            window.location.href = "";
        }

        return Promise.reject(customError);
    }
);